<template>
  <validation-observer ref="simpleRules">
    <b-card-code
      title="Form Pengajuan"
      :class="$route.name == 'detail-permintaan-data' ? 'detail-dispo' : ''"
    >
      <b-button
        v-show="$route.name === 'detail-permintaan-data' && authorize"
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        style="position: absolute;right: 15px;top: -10px;"
        type="submit"
        @click.prevent="goEdit"
      >
        <span class="align-middle">Edit Permintaan Data</span>
      </b-button>
      <b-row class="match-height">
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Instansi"
            rules="required"
          >
            <b-form-group
              label="Nama Instansi"
              label-for="Nama Instansi"
            >
              <b-form-input
                id="Instansi"
                v-model="instansi"
                placeholder="Input Instansi"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="No. Handphone"
            rules="required"
          >
            <b-form-group
              label="No. Handphone"
              label-for="No. Handphone"
            >
              <b-form-input
                id="number"
                v-model="number"
                placeholder="Input No. Handphone"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Alamat"
            rules="required"
          >
            <b-form-group
              label="Alamat"
              label-for="Alamat"
            >
              <b-form-input
                id="Alamat"
                v-model="address"
                placeholder="Input Alamat"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="Tanggal"
            >
              <b-form-datepicker
                id="date-datepicker"
                v-model="date"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <b-form-input
                id="Email"
                v-model="email"
                placeholder="Input Email"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <h5> File Permintaan Data</h5>
          <b-form-group label-for="FileSurat">
            <b-row
              v-if="gantiFile == false && file !== null"
              class="match-height"
            >
              <b-col
                v-for="item in file"
                :key="item.access_url"
                md="12"
                class="fileSurat"
              >
                <div
                  class="open-file"
                  @click="openFile(item.access_url)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="56"
                  />
                  <h5 class="ml-1">
                    Open File
                    <span> {{ item.real_filename }}{{ item.ext }} </span>
                  </h5>
                </div>
              </b-col>

              <b-button
                v-show="$route.name === 'edit-permintaan-data'"
                v-model="gantiFile"
                variant="outline-primary"
                class="bg-gradient-primary mt-1 ml-1"
                style="height: 29px"
                size="sm"
                @click.prevent="toggleFile"
              >
                <span class="align-middle">Ganti File</span>
              </b-button>
            </b-row>

            <b-row v-else>
              <b-col md="12">
                <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;">
                  <b-form-file
                    id="FileSurat"
                    ref="file"
                    v-model="file"
                    type="file"
                    placeholder="Input File Surat"
                    drop-placeholder="Drop file here..."
                    :disabled=" $route.name !== 'edit-permintaan-data'"
                    multiple="multiple"
                    @change="fileChange"
                  />

                  <b-button
                    v-show="file !== null"
                    v-model="gantiFile"
                    variant="outline-primary"
                    class="bg-gradient-primary mt-1"
                    @click.prevent="toggleFile"
                  >
                    <span class="align-middle">Open File</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Kategori"
            label-for="Kategori"
          >
            <v-select
              v-model="kategori"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :disabled="$route.name == 'detail-permintaan-data'"
              placeholder="Pilih Kategori"
              :options="optionsKategori"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tag"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-col>

        <b-col md="6">
          <b-form-group
            label="Divisi/ Bagian/Unit Terkait"
            label-for="selected"
          >
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              multiple
              :disabled="$route.name == 'detail-permintaan-data'"
              placeholder="Pilih Divisi"
              :close-on-select="false"
              :options="options"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <label for="textarea-default">Deskripsi</label>
          <b-form-textarea
            id="textarea-default"
            v-model="deskripsi"
            placeholder="Textarea"
            rows="3"
          />
        </b-col>

        <b-col md="6">
          <label for="textarea-default">Catatan</label>
          <b-form-textarea
            id="textarea-default"
            v-model="catatan"
            placeholder="Textarea"
            rows="3"
          />
        </b-col>
      </b-row>

    </b-card-code>

    <b-row class="match-height">
      <b-col md="7">
        <b-card-code
          title="Status Permintaan Data"
          style="max-height: 200px;"
        >
          <v-select
            v-model="selectedPerintah"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :disabled="!authorize"
            placeholder="Pilih Status"
            :options="[{value: 1, text: 'Open' }, {value: 2, text: 'Close' }]"
            class="mb-2"
          />
          <b-button
            v-show="$route.name !== 'detail-permintaan-data'"
            variant="outline-primary"
            class="bg-gradient-primary "
            type="submit"
            @click.prevent="validationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Simpan</span>
          </b-button>
        </b-card-code>
      </b-col>

      <b-col md="5">
        <b-card-code
          title="Riwayat Permintaan Data"
          class="activity"
        >
          <app-timeline
            v-for="data in activity"
            :key="data.id"
          >
            <app-timeline-item
              :title="data.user.name"
              :subtitle="data.message"
              :time="dateFormat(data.updated_at)"
              variant="info"
              style="padding-bottom:20px"
            />
          </app-timeline>
        </b-card-code>
      </b-col>
    </b-row>

  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import store from '@/store/index'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    ToastificationContent,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      NoSurat: '',
      instansi: '',
      email: '',
      number: '',
      address: '',
      gantiFile: false,
      file: [],
      tags: [],
      date: null,
      AllData: [],
      kategori: [],
      authorize: false,
      selected: [],
      activity: [],
      selectedPerintah: [],
      deskripsi: '',
      catatan: '',
      options: [{ item: '', name: '' }],
      optionsJenis: [
        { value: 1, text: 'Transaksi' },
        { value: 2, text: 'Non Transaksi' },
      ],
      optionsKategori: [
        { value: 1, text: 'Produk,' },
        { value: 2, text: 'Layanan' },
        { value: 3, text: 'Proses Produksi' },
        { value: 4, text: 'Pengiriman' },
        { value: 5, text: 'Paska Pengiriman' },
        { value: 6, text: 'Harga' },
      ],
    }
  },
  created() {
    this.authorize = store.state.userData.data.is_admin === 1
  },
  mounted() {
    this.getRole()
    this.getDetail()
    this.getActivity()
  },
  methods: {
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Request',
        },
      })
      this.file = data.data
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addRequest()
        }
      })
    },

    goEdit() {
      window.location.href = `/permintaan-data/edit/${this.NoSurat}`
    },

    toggleFile() {
      if (this.gantiFile === false) {
        this.gantiFile = true
      } else {
        this.gantiFile = false
      }
    },

    async getDetail() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`/siap/request/detail/${param}`)
      this.AllData = data
      this.NoSurat = data.ref_num
      this.kategori = data.category.name
      this.number = data.phone
      this.email = data.email
      this.instansi = data.institution
      this.date = data.date
      this.address = data.address
      this.catatan = data.note
      this.deskripsi = data.desc
      this.file = data.file
      this.tags = data.tag.map(e => e.name)
      this.selected = data.detail.responder.map(e => ({
        value: e.role_id,
        text: e.role_name,
        user_id: e.user_id,
        role_id: e.role_id,
        receiver_type: 'Responder',
      }))

      this.selectedPerintah = {
        value: data.status.id,
        text: data.status.name,
      }
    },

    async addRequest() {
      const responder = this.selected.map(e => ({
        user_id: e.user_id,
        role_id: e.value,
        receiver_type: e.receiver_type,
      }))
      await axios
        .post(`siap/request/update/${this.NoSurat}`, {
          cat: this.kategori,
          phone: this.number,
          email: this.email,
          institution: this.instansi,
          date: dayjs(this.date).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          address: this.address,
          note: this.catatan,
          desc: this.deskripsi,
          file: this.file,
          tags: this.tags,
          send_to: responder,
          status: this.selectedPerintah.text,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          // window.location.href = `detail-disposisi/${response.data.data.id}`
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.errors.map(e => e.message),
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async getRole() {
      const { data } = await axios.get('siap/account/receiver-type', {
        params: {
          feature: 'Disposition',
          show_all: 'true',
        },
      })
      this.options = data.map(e => ({
        value: e.role.id,
        text: e.role.name,
        user_id: e.user.id,
        role_id: e.role.id,
        receiver_type: 'Responder',
      }))
    },

    async getActivity() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`siap/request/activity/${param}`)
      this.activity = data.data
    },

    dateFormat(time) {
      const formatted = dayjs(time).format('DD/MM/YYYY (HH:mm)')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },

    openFile(e) {
      window.open(e, '_blank')
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}

.detail-dispo {
  .form-control,
  .custom-select {
    border: unset;
    border-bottom: 1px solid #d8d6de;
    border-radius: 0;
    pointer-events: none;
  }
}

[dir] .vs--disabled .vs__selected {
  background-color: #00427a;
  color: #fff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #615f6f;
}

.fileSurat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #a6a6a6;
    display: block;
    margin-top: 5px;
    font-size: 12px;
  }
}
.open-file {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  transition: background-color 0.5s ease;
  &:hover {
    background: #efefef;
  }
}

.activity {
  .card-body {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
